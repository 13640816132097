import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const Header = styled.header`
  background: ${props => props.theme.colors.background};
  width: 100%;
  padding: ${props => props.theme.spacing[6]} 0;
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 ${props => props.theme.spacing[6]};

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  a {
    display: block;
    padding: ${props => props.theme.spacing[2]} 0;
    text-decoration: none;
    color: ${props => props.theme.colors.grey_700};
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid transparent;
    &:hover {
      color: ${props => props.theme.colors.grey_1000};
    }
  }

  li {
    display: inline-block;
    margin-left: ${props => props.theme.spacing[8]};

    &:first-of-type {
      margin-left: 0;
    }
  }
`

const Logo = styled.div`
  margin-bottom: ${props => props.theme.spacing[2]};

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    margin-bottom: 0;
  }

  a {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.25rem;
    color: ${props => props.theme.colors.grey_1000};
  }
`

const List = styled.ul`
  // display: block;
`

const activeLinkStyle = {
  color: '#1f2933',
  borderBottom: '2px solid #1f2933',
}

const Menu = () => {
  const { menuLinks, title } = useSiteMetadata()
  return (
    <Header>
      <Nav>
        <Logo>
          <Link to="/">{title}</Link>
        </Logo>
        <List>
          {menuLinks.map(link => (
            <li key={link.name}>
              <Link to={link.slug} activeStyle={activeLinkStyle}>
                {link.name}
              </Link>
            </li>
          ))}
        </List>
      </Nav>
    </Header>
  )
}

export default Menu
