import React from 'react'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 2em 0;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  color: ${props => props.theme.colors.grey_600};
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`

const Footer = () => {
  const { title } = useSiteMetadata()
  return (
    <Wrapper>
      <List>
        <Item>
          &copy; {new Date().getFullYear()} {title}
        </Item>
        <Item>
          <a href="#top">&uarr; Top</a>
        </Item>
      </List>
    </Wrapper>
  )
}

export default Footer
